.footer-main-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2.5rem 0;
  background-color: rgb(227, 227, 227);
  /* opacity: 0.5; */
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.footer-main-div p {
  font-size: 1.2rem;
  margin: 0;
  margin-bottom: 0.5rem !important;
}
.footer-main-div p a {
  text-decoration: none;
  color: brown;
}
@media only screen and (max-width: 668px) {
  .footer-main-div p,
  .footer-main-div p a {
    font-size: 1rem;
    color: #000;
    letter-spacing: 0.6px;
  }
  .footer-main-div p a {
    color: #007bff;
  }
}
