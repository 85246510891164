.master-agent {
  margin-left: 38rem;
}
@media only screen and (max-width: 668px) {
  .master-agent {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
    width: 100%;
  }
  .admin-details-img {
    position: relative;
    width: 98%;
    max-height: 96%;
    height: auto;
    margin: 0 auto;
  }
  .admin-details-img img {
    width: 100%;
  }
  .content {
    width: 100%;
    height: 100%;
    padding: 0px 0;
    position: absolute;
    top: 0;
    z-index: 999;
    background: linear-gradient(
      to bottom,
      rgba(42, 244, 255, 0.302),
      rgba(0, 0, 0, 0.73)
    );
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 50px;
    text-shadow: 5px 5px 4px black;
  }
  .master-agent {
    margin-left: 0px !important;
  }
}
