.main-agent-card-div {
  width: 100%;
  height: auto;

  padding-bottom: 20px;
  margin-top: 395px;
}

.main-agent-card-div .agent-card-div {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  padding-bottom: 1rem !important;
  position: relative;
  margin-top: -13.5rem;
}
.main-agent-card-div .agent-card-div .single-agent-card {
  background-color: #008000;
  padding: 0.8rem;
  border: 1.8rem solid #55c13a;
  border-radius: 0.5rem;
  text-align: center;
  position: relative;
  padding-top: 1px !important;
  margin-top: 15px;
}
.single-agent-card .icon {
  font-size: 4.8rem;
  background-color: #adff2f;
  color: #ff8e2b;
  padding: 7px 10px 0px 10px;
  border-radius: 50%;
  outline: none;
}
.agent-card-info h4 {
  font-size: 1.8rem;
  margin: 0;
  padding-top: 30px;
  font-weight: 500;
  text-decoration: none;
}
.agent-link {
  text-decoration: none;
  color: #de8d2b;
  text-shadow: 5px 5px 4px black;
  font-family: system-ui;
  font-size: 1.2rem;
}
.agent-card-info p {
  color: #de8d2b;
  letter-spacing: 0.6px;
  line-height: 24px;
  margin-bottom: 0;
  padding: 5px 10px;
}
@media only screen and (max-width: 668px) {
  .main-agent-card-div .agent-card-div {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .main-agent-card-div .agent-card-div .single-agent-card {
    border: 0.3rem solid #de8d2b;
    /* padding: 30px 0rem; */
    width: 89%;
  }
  .agent-card-info_first h4 {
    font-size: 1.4rem;
    padding-top: 0px;
    margin-top: -26px;
    margin-bottom: 5px;
  }
  .agent-card-info h4 {
    font-size: 1.4rem;
    margin-top: -46px;
  }

  .agent-card-info p,
  .agent-card-info_first p {
    font-size: 0.9rem;
    color: #de8d2b;
  }
  .admin_img {
    width: 185px;
  }
  .super_img {
    width: 100px;
    border: 2px solid yellow;
    border-radius: 50%;
    margin-bottom: 30px;
    background: #ff5500;
  }
  .super .super_img {
    margin-top: 40px !important;
  }
}
