.userQuestion_heading {
  width: 98%;
  margin: 10px auto;
  background-color: #008000;
  border-radius: 10px;
  padding: 15px 0px 2px;
}
.userQuestion_heading h2 {
  background: rgb(7, 190, 7);
  width: 95%;
  margin: 0 auto;
  font-size: 15px;
  line-height: 25px;
  color: #fff;
  letter-spacing: 0.8px;
  text-align: center;
  padding: 5px 0px;
  margin-bottom: 20px;
}
.hyperlink {
  cursor: pointer;
  border-radius: 10px;
  background: green;
  box-shadow: inset 0 0 3px 3px rgba(89, 238, 255, 0.628);
  border: 1px solid black;
  text-align: center;
  padding: 3px 0;
  width: 95%;
  margin: 10px auto;
}
.hyperlink p {
  font-size: 10px;
}
.question_heading {
  background-color: rgb(0 128 0);
  width: 98%;
  margin: 10px auto;
  padding: 4px 0px 4px 0px;
  border-radius: 10px 10px 0px 0px;
}
.question_heading h3,
.question_heading h4 {
  background: rgb(7, 190, 7);
  text-align: center;
  padding: 5px 20px;
  margin: 10px 0;
  font-size: 14px;
}
.question_section {
  background-color: #d6fcfa;
  width: 98%;
  margin: 0 auto;
}
.question_section img {
  width: 20px;
  height: 20px;
}
.question_section ul {
  padding: 20px 10px;
}
.question_section ul li {
  list-style: none;
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}
.question_section ul li a {
  margin-left: 10px;
  text-decoration: none;
  color: #000;
  font-weight: 600;
}
