.admin-list-main-div {
  width: 100%;
  padding: 5rem 0;
}
.admin-list-color {
  width: 85%;
  background-color: rgb(31, 240, 115);
  margin: 0 auto;
  padding: 4rem 0rem;
  border-radius: 20px;
}
.tabel-list {
  width: 80%;
  margin: 0 auto;
}
.tabel-desc {
  width: 100%;
}
.tabel-head {
  background-color: #1ff073;
  font-weight: 700;
  border-top: 2px solid #9de7bf;
}
.tabel-head td {
  padding: 0.6rem 0.4rem;
  font-size: 1.1rem;
  color: #2e3035;
}
.table-body-td {
  background-color: #c3ffe5;
}
.table-body-td td {
  font-size: 1.1rem;
  padding: 0.8rem 0.6rem;
  border: 1px solid #aaa;
}
table {
  border-spacing: 0 !important;
  border-top: 2px solid #9de7bf;
}
.social-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.6rem;
}
.social-icon a img {
  width: 18px;
  border-radius: 50%;
  border: 1px solid rgb(223, 95, 255);
  padding: 2px;
}
.admin-list h1 {
  font-size: 2rem;
  font-weight: 500;
  margin-left: 7rem;
}
.complain-text {
  text-decoration: none;
}
@media only screen and (max-width: 668px) {
  .admin-list-main-div {
    padding: 1.3rem 0;
  }
  .admin-list-color {
    width: 95%;
    padding: 1.2rem 0;
  }
  .admin-list h1 {
    font-size: 1.2rem;
    margin-left: 0.5rem;
    margin-top: 3px;
    text-align: center;
  }
  .tabel-list {
    width: 100%;
  }
  .tabel-head td {
    font-size: 0.75rem;
    text-align: center;
  }
  .table-body-td td {
    font-size: 0.75rem;
    padding: 0.4rem 0.2rem;
    text-align: center;
  }
  .adminComplain {
    text-decoration: none;
    color: blue;
  }
}
