.facebook-group-img {
  width: 100%;
  height: 300px;
  position: relative;
  margin-top: 40px;
  /* border: 2px solid #fff; */
  /* border-radius: 5px; */
}

.facebook-group-img img {
  width: 100%;
  height: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.facebook-group-info {
  position: absolute;
  background: transparent;
  background-image: linear-gradient(180deg, #476e71 0%, #f2295b 100%);
  opacity: 0.5;
  top: 0;
  height: 300px;
  width: 100%;
}
.facebook-group-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 100%; */
  z-index: 999;
  opacity: 1;
  position: absolute;
  top: 0;
}
.facebook-group-details h2 {
  font-size: 2rem;
  font-weight: 700;
  color: #fff;
  z-index: 999;
}
.facebook-group-details p {
  color: #fff;
}
.facebook-group-details button {
  padding: 0.5rem 1rem;
  color: #fff;
  background-color: #ff4500;
  outline: none;
  border: none;
}
.facebook-group-details button a {
  color: #fff;
  text-decoration: none;
  letter-spacing: 0.7px;
  font-size: 1.2rem;
}
@media only screen and (max-width: 668px) {
  .facebook-group-img img {
    width: 100%;
    object-fit: cover;
  }
  .facebook-group-details {
    /* margin-top: -134px; */
  }
  .facebook-group-details button {
    margin: 0.5rem 0;
    border-radius: 5px;
  }
  .facebook-group-details h2 {
    font-size: 1.3rem;
    padding: 0 0.2rem;
    font-weight: 500;
    margin: 62px 48px 0 0;
  }
  .facebook-group-details p {
    padding: 0 0.9rem;
    line-height: 28px;
    text-align: justify;
    font-size: 0.8rem;
  }
  .facebook_section {
    padding: 30px 20px;
    color: #fff;
    font-size: 0.9rem;
    text-shadow: 5px 5px 4px black;
    letter-spacing: 0.8px;
  }
}
