.website-link-main-div {
  background-color: transparent;
  border: 2px solid orange;
  background: linear-gradient(
    to top,
    rgba(2, 123, 136, 0.517),
    rgba(183, 2, 2, 0.722)
  );
  width: 99%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 4rem;
}
.facebook-group-link {
  /* padding: 2rem 0; */
  text-align: center;
}
.facebook-group-link p {
  font-size: 0.9rem;
  color: #fff;
  /* border: 3px solid #e3f400; */
  padding: 7px 9px;
}
.facebook-group-link button {
  background-color: transparent;
  background: linear-gradient(
    to top,
    rgba(0, 84, 103, 0.669),
    rgba(246, 6, 6, 0.96)
  );
  transition: 0.3s all ease;
  border: none;
  padding: 0.6rem 2.5rem;
  font-size: 1rem;
  letter-spacing: 0.6px;
  border-radius: 5px;
}
.facebook-group-link button a {
  text-decoration: none;
  color: #fff;
  font-size: 1rem;
}
.facebook-group-link button span {
  margin-left: 10px;
}
@media only screen and (max-width: 668px) {
  .website-link-main-div {
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
  }
  .facebook-group-link {
    /* padding: 1rem 0; */
  }
  .hotline_number {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 0px;
    background-color: rgb(126 185 192);
  }
  .hotline_number button {
    background-color: #0d9c0d;
    font-size: 2.5rem;
    padding: 4px 16px;
    outline: none;
    border: none;
    color: #fff;
    border-radius: 23%;
  }
}
