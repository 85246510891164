.admin-background {
  width: 100%;
  height: 370px;
  position: relative;
}
.admin-background img {
  width: 100%;
  height: 370px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: cover;
  position: relative;
  margin: 0 auto;
  /* border: 6px solid #fff; */
}
.opacity-color {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0.7;
  mix-blend-mode: multiply;
  background-color: transparent;
  background-image: linear-gradient(250deg, #1de338 0%, #892af7 100%);
}
.admin-background-info {
  position: absolute;
  top: 0;
  margin-top: 7rem;
  margin-left: 7rem !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.admin-icon {
  font-size: 2.3rem;
  background-color: #dcdc2e;
  color: #d31919;
  padding: 1.5rem;
  border-radius: 50%;
  border: 4px solid #d31919;
}
.admin-background-info h1 {
  color: #fff;
}
.admin-details {
  text-align: center;
  width: 100%;
}
.admin-details-list {
  width: 100%;
  background: linear-gradient(
    30deg,
    rgba(38, 38, 252, 0.891),
    rgba(29, 255, 29, 0.908),
    rgba(255, 41, 41, 0.908)
  );
  color: #fff;

  padding: 3.5rem 0;
}
/* .admin-details-img {
  width: 100%;
  background-color: transparent;
  background-image: linear-gradient(180deg, #1c9439 0%, #05260d 100%);
  padding: 2rem 0;
} */
.admin-details-list p {
  color: #fff;
}

.admin-details-list a {
  color: #fff;
  text-decoration: none;
  font-size: 2.1rem;
  /* border: 4px solid #34ca45; */
  width: 80%;
  display: block;
  margin: 0 auto;
  padding: 0.6rem 0;
}
@media only screen and (max-width: 668px) {
  .admin-background-info {
    margin-left: 2.5rem;
  }
  .admin-background-info i {
    background-color: greenyellow;
    font-size: 5rem;
    padding: 9px;
    color: #fff;
    border-radius: 50%;
    text-shadow: 5px 5px 4px black;
  }
  .admin-icon {
    font-size: 1.6rem;
  }
  .admin-background-info h1 {
    font-size: 1.3rem;
  }
  .admin-details-list p {
    letter-spacing: 0.5px;
    line-height: 26px;
    font-size: 1rem;
    padding: 0 0.6rem;
    text-shadow: 5px 5px 4px black;
    text-align: center;
  }
  .admin-details-list a {
    font-size: 1.9rem;
    text-shadow: 5px 5px 4px black;
    width: 95%;
    padding: 0.4rem 0;
    margin-top: 50px;
    color: #e3f400;
    background: rgb(1, 159, 187);
    font-weight: 800;
    color: #fff;
    box-shadow: 0 0px 3px 5px rgba(1, 57, 130, 0.29);
    transition: 0.3s all ease;
  }

  .admin-details-img {
    position: relative;
    width: 98%;
    max-height: 96%;
    height: auto;
    margin: 0 auto;
  }
  .admin-details-img img {
    width: 100%;
  }
  .content {
    width: 100%;
    height: 100%;
    padding: 0px 0;
    position: absolute;
    top: 0;
    z-index: 999;
    background: linear-gradient(
      to bottom,
      rgba(42, 244, 255, 0.302),
      rgba(0, 0, 0, 0.73)
    );
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 50px;
    text-shadow: 5px 5px 4px black;
  }
  .link_dres {
    text-decoration: none;
  }
  .adminPic {
    width: 194px !important;
    height: 116px !important;
  }
}
