.question-main-div {
  width: 100%;
  height: auto;
  background-color: transparent;
  background: linear-gradient(30deg, rgb(0, 255, 149), rgb(124, 201, 0));
  margin: 0;
  padding: 1.2rem 0rem;
}
.question-section {
  width: 85%;
  margin: 0 auto;
  padding: 0.4rem;
  border-radius: 5px;
}
.question-section p {
  color: #000;
  /* line-height: 28px; */
  letter-spacing: 0.7px;
  font-size: 0.9rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.question-section p small {
  font-size: 17px;
  display: block;
  font-weight: 600;
}
.question-section p a {
  color: #000;
}
.question-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.left-question {
  width: 100%;
  height: 550px;
  padding: 0.4rem;
  background-color: transparent;
  background-image: linear-gradient(180deg, #62ee80 0%, #54d5f8 100%);
}
.left-question h3 {
  font-size: 1rem;
  color: #4b4f58;
  margin-bottom: 1.5rem;
  padding: 0 0.2rem;
}
.left-question p {
  letter-spacing: 0.7px;
  line-height: 25px;
  font-size: 0.9rem;
  color: #4b4f6f;
  padding: 0 0.4rem;
}
.left-question h5 {
  font-size: 15px;
  font-weight: 500;
  color: #0170b9;
  padding: 0 0.4rem;
}
.agent-point {
  font-size: 1rem;
  color: #4b4f6f;
  font-weight: 550;
  padding: 0 0.4rem;
}
.agent-point-ul {
  list-style: none;
  padding: 0 0.4rem;
}
.agent-point-ul li {
  color: #4b4f6f;
  padding-bottom: 0.9rem;
}
.agent-point-ul li img {
  width: 15px;
}
.coin-buy {
  padding: 0.4rem;
  margin-top: 0.5rem;
  background-color: transparent;
  background-image: linear-gradient(180deg, #62ee80 0%, #54d5f8 100%);
  padding-bottom: 2rem !important;
}
.coin-buy h3 {
  font-size: 1rem;
  color: #4b4f58;
  margin-bottom: 1.5rem;
  padding: 0 0.2rem;
}
.coin-buy p {
  letter-spacing: 0.7px;
  line-height: 25px;
  font-size: 0.9rem;
  color: #4b4f6f;
  padding: 0 0.2rem;
}
.coin-buy a {
  margin-top: 0.6rem;
  font-size: 1rem;
}
@media only screen and (max-width: 668px) {
  .question-section {
    width: 92%;
    padding: 0.3rem;
  }
  .question-flex {
    display: flex;
    flex-direction: column;
  }
  .left-question {
    height: auto;
    padding: 0.6rem 0;
  }
  .agent-point {
    font-size: 0.9rem;
  }
  .agent-point-ul li {
    font-size: 0.9rem;
  }
  .redCircle {
    display: flex;
    align-items: flex-start;
  }
  .redCircle img {
    width: 16px;
    height: 16px;
    margin-top: 16px;
    margin-right: 8px;
  }
  .redCircle p {
    font-size: 14px;
  }
}
