.sliderContainer {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
}
.sliderContainer .slider_img {
  width: 95%;
  height: 100%;
  margin: 200px 0 auto;
  text-align: center;
  border-radius: 15px;
}
