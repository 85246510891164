.user-main-div {
  width: 100%;
  position: relative;
}
.user-id {
  width: 100%;
  background-color: transparent;
  background-image: linear-gradient(180deg, #37a53c 0%, #fdfdfd 100%);
  opacity: 0.9;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  padding: 1rem 0;
  height: 74px;
}
.user-main-div h4 {
  position: absolute;
  top: 33px;
  text-align: center;
  left: 360px;
}
@media only screen and (max-width: 668px) {
  .user-main-div h4 {
    left: 0;
    font-size: 1rem;
    padding: 0 34px;
    top: 25px;
    letter-spacing: 0.6px;
  }
  .userIdBtn {
    width: 90%;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 5px;
    margin: 0 auto;
  }
  .userButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 6px 6px;
    border: 2px solid #000;
    background-color: #fff;
    border-radius: 5px;
  }
  .userButton span {
    margin-left: 5px;
  }
}
