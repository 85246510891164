.search-agent {
  display: flex;
  justify-content: center;
  margin-right: 10px;
  margin-bottom: 10px;
  align-items: center;
}
.search-agent p {
  margin-right: 5px;
}
.search-agent input {
  font-size: 1rem;
  height: 22px;
  padding: 8px;
  width: 83%;
  border: none;
  border-radius: 32px;
  margin-left: 19px;
}
.search-agent button {
  height: 44px;
  position: relative;
  background-color: transparent;
  border: none;
}
.search-agent button i {
  position: absolute;
  right: 25px;
  font-size: 1rem;
  margin-top: -8px;
}
.heading_line {
  background-color: rgb(230 230 230);
}
.heading_line small {
  display: block;
  padding: 2px 20px;
  font-size: 10px;
  background: #021b35;
  color: #edff2b;
  width: 50%;
  border-radius: 0 0 3em 3em;
  text-align: center;
  margin: 0 auto;
}
.heading_line p {
  background-color: black;
  font-size: 0.5rem;
  color: #edff2b;
  text-align: center;
  padding: 4px 0px;
  margin: 0;
}
