.general-quesiton-main-div {
  position: relative;
  width: 100%;
  margin-top: 25px;
}
.general-question-div {
  width: 100%;
  height: 450px;
  background-color: transparent;
  background: linear-gradient(
    30deg,
    rgba(0, 0, 255, 0.776),
    rgba(255, 0, 0, 0.713)
  );
  /* border: 2px solid #fff; */
  /* border-radius: 15px; */
  position: relative;
  margin-top: -27px;
}
.general-question-details {
  width: 100%;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.general-question-details h1 {
  color: #046bd2;
  display: block;
  width: 90%;
}
.general-question {
  border-radius: 20px;
  box-shadow: 0 0 5px 5px rgba(36, 56, 5, 0.468);
  background: linear-gradient(
    30deg,
    rgba(111, 111, 255, 0.6),
    rgba(0, 128, 0, 0.321),
    rgba(255, 0, 0, 0.258)
  );
  border-radius: 20px;
  width: 100%;
  margin: 0 auto;
  margin-top: 1.3rem !important;
}
/* .general-questionul {
  list-style-position: inside;
} */
ul {
  list-style-position: outside;
  /* list-style: none; */
  padding: 2rem 2.5rem;
}
.general-question ul li {
  padding-bottom: 1rem;
  font-size: 1.3rem;
}
.general-question ul li:last-child {
  padding-bottom: 0;
}
.general-question ul li img {
  width: 21px;
  padding-right: 7px;
}
.general-question ul li a {
  text-decoration: none;
  color: #fff;
  font-size: 0.9rem;
}
@media only screen and (max-width: 668px) {
  .general-question-details h1 {
    font-size: 1.2rem;
    margin-top: 0rem;
    text-align: center;
    width: 100%;
    background-color: #fff;
    padding: 18px 0px;
    color: #000;
  }
  .general-Questions {
    width: 94%;
    /* padding-bottom: 16px; */
    border: 3px solid #ffa500;
    padding-right: 8px;
    padding-left: 8px;
  }
  .general-question-div {
    height: 927px;
  }
  .general-question {
    width: 95%;
    margin-top: 1rem;
  }
  .general-question ul li {
    font-size: 1.2rem;
    color: #000;
  }
  #second_slot {
    margin-top: 20px;
  }
}
