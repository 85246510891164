.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(33 130 62);
  padding: 0.6rem 5rem;
  /* border: 6px solid #000; */
  /* overflow-x: hidden; */
}
.navigation .main-logo {
  width: 310px;
}
.navigation .main-logo img {
  width: 100%;
}
.navigation .nav-menubar .nav-menu {
  display: flex;
  list-style: none;
  margin: 0;
}
.navigation .nav-menubar .nav-menu .nav-menu-link {
  padding: 0 1rem;
  color: #fff;
}
.navigation .nav-menubar .nav-menu .nav-menu-link a {
  color: #fff;
  text-decoration: none;
  letter-spacing: 0.6px;
  font-size: 1rem;
}
.menu-bar-icon {
  display: none;
}

@media only screen and (max-width: 668px) {
  .navigation {
    padding: 0rem 1rem;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 10;
  }
  .navigation .main-logo {
    margin-top: 0px;
    margin-right: 78px;
  }
  .navigation .main-logo img {
    width: 97%;
  }
  .navigation .nav-menubar .nav-menu {
    position: absolute;
    background-color: rgb(29 128 59 / 98%);
    border-radius: 0px;
    color: #fff;
    display: flex;
    top: 20;
    right: 0;
    flex-direction: column;
    height: auto;
    width: 100%;
    padding: 0.4rem 1.5rem;
    transform: translateX(-100%);
    transition: all 0.3s ease-in;
    opacity: 0;
  }
  .navigation .nav-menubar .nav-menu .nav-menu-link {
    padding: 0.7rem 2.5rem;
  }
  .navigation .nav-menubar .nav-menu.active {
    transform: translateX(0);
    opacity: 1;
    z-index: 999;
  }
  .navigation .nav-menubar .nav-menu .nav-menu-link a {
    color: #fff;
    font-weight: 800;
  }
  .menu-bar-icon {
    display: block;
    background-color: transparent;
    border: none;
    font-size: 1.6rem;
    color: #fff;
    position: absolute;
    right: 0;
    outline: none;
    margin-right: 34px;
    top: 32px;
    background: #ff4500;
    border-radius: 50%;
    height: 47px;
    width: 48px;
    line-height: 48px;
    border: 1px solid #fff;
  }
  /* marguee  */
  .heading {
    background-color: #021b35;
    color: #fff;
    margin-bottom: 0;
    font-size: 1.1rem;
    padding: 8px 0px;
  }
  .heading-section {
    position: relative;
    margin-bottom: -6px;
  }
  .notis {
    height: 40px;
    position: absolute;
    top: -16px;
    z-index: 1;
    color: #fff;
    background-color: #021b35;
    width: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 0.14rem solid #fff;
  }
  .same_color {
    background: linear-gradient(
      30deg,
      rgba(0, 0, 255, 0.776),
      rgba(255, 0, 0, 0.713)
    );
  }
}
